<template>
  <div class="steam-list">
    <!--列表-->
    <el-table :data="streamList" highlight-current-row style="width: 95%">
      <el-table-column prop="id" label="id" width="100"></el-table-column>
      <el-table-column prop="appName" label="app名称"></el-table-column>
      <el-table-column prop="streamName" label="流名称"></el-table-column>
      <el-table-column prop="status" label="状态" width="50"></el-table-column>
      <el-table-column prop="createTime" :formatter="formatTime" label="创建时间" width="100"></el-table-column>
      <el-table-column prop="endTime" :formatter="formatTime" label="结束时间" width="100"></el-table-column>
      <el-table-column prop="address" label="地址" width="300"></el-table-column>
      <el-table-column prop="name" label="秘钥" width="300"></el-table-column>
      <el-table-column prop="courseListStr" label="有权限课程" width="300"></el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </div>
</template>

<script>
import { liveApi } from "@/api/liveApi";
import { epochToDateString } from "@/util/TimeUtil";

export default {
  data() {
    return {
      streamList: [],
      currentPage: 1,
      totalPage: 1,
    };
  },

  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    query() {
      liveApi
        .listStream(this.currentPage)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;

            for (let item of data) {
              if (item.courseList) {
                item.courseListStr = item.courseList.join(",");
              } else {
                item.courseListStr = "";
              }
            }

            this.streamList = data;
          }
        })
        .catch((err) => {
          this.$message({
            messge: "获取stream失败" + err,
            type: "error",
          });
        });
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
  },

  mounted() {
    this.query();
  },
};
</script>

<style lang="less" scoped>
.steam-list {
  width: 100%;
}
</style>
